// Sets up BlinkID to work with BASIC WASM mode and the single side recognizer.
// Locations and licence key are set in via <blinkid-in-browser> tag constructed
// in ApplicationHelper#blinkid_in_browser_tag
//
export function setupBlinkID(element) {
  element.allowHelloMessage = true;

  /*
   * Using BASIC mode to target the majority of browsers and also to keep
   * the app size smaller (each WASM mode is 13MB).
   * See https://github.com/BlinkID/blinkid-in-browser#deployment-of-wasm-files
   */
  element.wasmType = "BASIC";

  element.recognizers = ["BlinkIdSingleSideRecognizer"];

  // These allow capturing the image from the scan.
  element.recognizerOptions = {
    BlinkIdSingleSideRecognizer: {
      returnEncodedFullDocumentImage: true,
      returnFullDocumentImage: true,
      // This will return blank results on successful
      // scans and needs to be handled better before
      // turning on.
      // allowUnverifiedMrzResults: false,
      fullDocumentImageDpi: 300,
      // Some countries have strict rules about scanning passports
      // https://github.com/BlinkID/blinkid-ios/blob/v5.20.1/documentation/BlinkIDRecognizerResult.md#anonymised-fields
      // changing this mode allows us to scan them.
      anonymizationMode: 0,
    },
  };
}
