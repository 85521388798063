import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static outlets = ["jp-merchant-operation--line-item"];
  static targets = [
    "addNewItem",
    "consumableItemsTax",
    "consumableItemsTotal",
    "eightPercentTax",
    "eightPercentTotal",
    "generalItemsTax",
    "generalItemsTotal",
    "orderTax",
    "orderTotal",
    "required",
    "tenPercentTax",
    "tenPercentTotal",
    "itemsCounter",
    "applyCategoryToAllModal",
    "categoryName",
    "confirmTotalsWarningModal",
    "totalBelowMinimumWarning",
    "consumableTotalAboveMaximumWarning",
  ];

  connect() {
    if (
      !this.hasJpMerchantOperationLineItemOutlet &&
      this.hasAddNewItemTarget
    ) {
      this.addNewItemTarget.click();
    }

    this.refresh();
  }

  jpMerchantOperationLineItemOutletConnected(outlet, _) {
    // Retrieve the last line item outlet before the new one was added
    const protoLineItem = this.keptItems()[this.keptItems().length - 2];

    if (protoLineItem) {
      outlet.categoryTarget.value = protoLineItem.categoryTarget.value;

      outlet.reducedTaxRateTarget.checked =
        protoLineItem.reducedTaxRateTarget.checked;
      outlet.reducedTaxRateTarget.dispatchEvent(new Event("change"));

      outlet.isConsumableTarget.checked =
        protoLineItem.isConsumableTarget.checked;
      outlet.isConsumableTarget.dispatchEvent(new Event("change"));

      outlet.taxIncludedInTotalTarget.checked =
        protoLineItem.taxIncludedInTotalTarget.checked;
      outlet.taxIncludedInTotalTarget.dispatchEvent(new Event("change"));
    }
  }

  refresh() {
    const orderTotal = this.calculateOrderTotal();
    const orderTaxTotal = this.calculateOrderTax();
    this.orderTotalTargets.forEach((target) => {
      target.innerHTML = orderTotal;
    });

    this.orderTaxTargets.forEach((target) => {
      target.innerHTML = orderTaxTotal;
    });

    this.tenPercentTotalTarget.innerHTML = this.calculateTenPercentTotal();
    this.tenPercentTaxTarget.innerHTML = this.calculateTenPercentTax();
    this.eightPercentTotalTarget.innerHTML = this.calculateEightPercentTotal();
    this.eightPercentTaxTarget.innerHTML = this.calculateEightPercentTax();
    this.generalItemsTotalTarget.innerHTML = this.calculateGeneralItemsTotal();
    this.generalItemsTaxTarget.innerHTML = this.calculateGeneralItemsTax();
    this.consumableItemsTotalTarget.innerHTML =
      this.calculateConsumableItemsTotal();
    this.consumableItemsTaxTarget.innerHTML =
      this.calculateConsumableItemsTax();

    this.itemsCounterTarget.innerHTML = this.calculateTotalNumberOfItems();

    // Update some warnings
    const totalExcludingTax = this.calculateOrderTotalExclTax();
    const consTotalExlTax = this.calculateConsumableItemsTotalExclTax();

    this.showOrHideMinimumTotalWarning(totalExcludingTax);
    this.showOrHideConsumableTotalAboveMaximumWarning(consTotalExlTax);
  }

  items() {
    return this.jpMerchantOperationLineItemOutlets;
  }

  keptItems() {
    return this.items().filter((outlet) => !outlet.markedForDestruction());
  }

  calculateOrderTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.totalPriceExclTax);
      sum += parseFloat(outlet.tax);
    });

    return this.formatAmount(sum);
  }

  calculateOrderTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.tax);
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
      }
    });

    return sum;
  }

  calculateOrderTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.totalPriceExclTax);
    });

    return sum;
  }

  calculateTotalNumberOfItems() {
    return this.keptItems().reduce((sum, item) => sum + item.quantity, 0);
  }

  formatAmount(amount) {
    return new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      maximumFractionDigits: 0,
    }).format(amount);
  }

  toggleReducedTaxRate() {
    this.#toggleApplyAllAction(
      "toggleReducedTaxRateIcon",
      (item) => !item.isReducedTaxRate,
    );
  }

  toggleConsumable() {
    this.#toggleApplyAllAction(
      "toggleConsumableIcon",
      (item) => !item.isConsumable,
    );
  }

  toggleTaxIncludedInTotal() {
    this.#toggleApplyAllAction(
      "toggleTaxIncludedInTotalIcon",
      (item) => !item.taxIncludedInTotal,
    );
  }

  toggleAlcohol() {
    this.#toggleApplyAllAction("toggleAlcoholIcon", (item) => !item.isAlcohol);
  }

  //
  // Apply Category to All
  //

  showApplyCategoryToAllModal(event) {
    const selector = event.target;

    if (
      selector.value !== "" && // don't apply empty category
      this.keptItems().length > 1 && // don't apply to single line item
      this.isChildOfFirstKeptLineItem(selector) // triggered by first item
    ) {
      this.categoryNameTarget.innerText = selector.value;
      this.applyCategoryToAllModal.modal("show");
    }
  }

  get applyCategoryToAllModal() {
    const modal = $(`#${this.applyCategoryToAllModalTarget.id}`);

    return modal;
  }

  triggerApplyCategoryToAll() {
    this.applyCategoryToAllModal.modal("hide");
    this.applyCategoryToAll(this.keptItems()[0].categoryTarget.value);
  }

  isChildOfFirstKeptLineItem(element) {
    return this.keptItems()[0].element.contains(element);
  }

  applyCategoryToAll(category) {
    this.keptItems().forEach((outlet) => {
      outlet.categoryTarget.value = category;
    });
  }

  removeAllLineItems() {
    this.items().filter((outlet) => {
      outlet.destroyButtonTarget.click();
    });
    this.addNewItemTarget.click();
  }

  #toggleApplyAllAction(targetName, filter) {
    if (this.items().some(filter)) {
      this.items()
        .filter(filter)
        .forEach((item) => {
          item.targets.find(targetName).click();
        });
    } else {
      this.items().forEach((item) => {
        item.targets.find(targetName).click();
      });
    }
  }

  openConfirmTotalsWarningModal(_event) {
    const modal = $(`#${this.confirmTotalsWarningModalTarget.id}`);

    modal.modal("show");
  }

  showOrHideMinimumTotalWarning(orderTotalExclTax) {
    // This is hardcoded, we currently don't have this min check enforced
    // because we don't have the ability to combine orders yet
    // TODO(Dom): Have this loaded in from somewhere to match logic elsewhere
    if (orderTotalExclTax < 5000) {
      this.totalBelowMinimumWarningTarget.classList.remove("d-none");
    } else {
      this.totalBelowMinimumWarningTarget.classList.add("d-none");
    }
  }

  showOrHideConsumableTotalAboveMaximumWarning(orderTotalExclTax) {
    // This is hardcoded, we currently don't have this maximum consumable
    if (orderTotalExclTax > 500000) {
      this.consumableTotalAboveMaximumWarningTarget.classList.remove("d-none");
    } else {
      this.consumableTotalAboveMaximumWarningTarget.classList.add("d-none");
    }
  }
}
